import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css'

class Description extends Component {
  constructor(props) {
    super(props)

    this.state = { terms: "null" }
  }

  componentWillMount() {
    const termsFrPath = require(`./problems/${this.props.pid}/p.md`)
    fetch(termsFrPath).then((response) => response.text()).then((text) => {
      this.setState({ terms: text })
    })
  }

  render() {
    return (
      <div className="content">
        <ReactMarkdown children={this.state.terms} 
          components={{img:({node,...props})=><img style={{maxWidth:'50%'}}{...props}/>}}        
          remarkPlugins={[remarkMath]}
          rehypePlugins={[rehypeKatex]}
        />
      </div>
    )
  }
}

export default Description;
import raw from 'raw.macro';

/*
export const text = (pid) => {
    const a = pid;
    return raw(`./problems/${a}/p.txt`);
}
*/

export const in_text = (pid) => {
    const a = pid;
    return raw(`./problems/${a}/in1.txt`);
}
export const out_text = (pid) => {
    const a = pid;
    return raw(`./problems/${a}/out1.txt`);
}
import React from 'react';

// Import Brace and the AceEditor Component
import brace from 'brace';
import AceEditor from 'react-ace';
import ReactResizeDetector from 'react-resize-detector'

// Import a Mode (language)
import 'brace/mode/java';
import 'brace/mode/python';
import 'brace/mode/c_cpp';
import 'brace/mode/csharp';
import 'brace/mode/javascript';

// Import a Theme (okadia, github, xcode etc)
import 'brace/theme/tomorrow';
import { toHaveStyle } from '@testing-library/jest-dom/dist/matchers';



export default class Editor extends React.Component {
    language_to_mode = {
        "C": "c_cpp",
        "C#": "csharp",
        "C++": "c_cpp",
        "Java": "java",
        "JavaScript": "javascript",
        "Python": "python",
    };

    constructor(props) {
        super(props);
        this.state = {
            editorHeight: "80vh",
            editorWidth: "auto"
        }
        //this.onChange = this.onChange.bind(this);
        this.onResize = this.onResize.bind(this)
    }

    onChange(newValue) {
        console.log('change', newValue);
    }

    onResize (w, h) {
        this.setState({
          editorHeight: h,
          editorWidth: w
        })
    }

    render() {
        return (
            <div>
                <ReactResizeDetector handleWidth handleHeight onResize={this.onResize} />
                <AceEditor
                    mode={this.language_to_mode[this.props.lang]}
                    theme="tomorrow"
                    fontSize = "11pt"
                    value={this.props.value}
                    onChange={this.props.onChangeValue}
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{
                        $blockScrolling: true
                    }}
                    height={this.state.editorHeight}
                    width={this.state.editorWidth}
                />
            </div>
        );
    }
}

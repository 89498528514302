//import './App.css';
import Home from './pages/Home';
import Problems from './pages/Problems';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
  useParams
} from "react-router-dom";

function SelectTopic() {
  return <h3>Please select a topic.</h3>
}



function About() {
  return <h2>About</h2>;
}

function AllProblems() {
  return <h2>All Problems</h2>;
}

function AProblem() {
  let { pid } = useParams();
  return <Problems pid={pid} />
}


export default function App() {
  let { pid } = useParams();
  return (
    <Router>
      <div>
        <ul>
          {/* 
          <li>
            <Link to="/">Home</Link>
          </li>
          
          <li>
            <Link to="/about">About</Link>
          </li> 
          
          <li>
            <Link to="/problems">Problems</Link>
          </li>
          */}
        </ul>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/problems" element={<AllProblems />} />
          <Route path="/problems/:pid" element={<AProblem />} />
        </Routes>
      </div>
    </Router>
  );
}











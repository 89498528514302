import React from 'react'
import Editor from '../components/Editor'
import SplitPane, { Pane } from 'react-split-pane';
import * as P from './text.js';
import {Buffer} from 'buffer';
import Description from './Description.js'

class Problems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: localStorage.getItem(this.props.pid)||``,
      input: P.in_text(this.props.pid),
      expected: P.out_text(this.props.pid),
      output: ``,
      language_id:localStorage.getItem('language_id')|| 62,
      splitPos: 400,
      running: false,
    };
    this.run = this.run.bind(this);
    this.codeChange = this.codeChange.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.expectedChange = this.expectedChange.bind(this);
    this.langChange = this.langChange.bind(this);
    localStorage.setItem('splitPos1', localStorage.getItem('splitPos1')|| 50);
    localStorage.setItem('splitPos2', localStorage.getItem('splitPos2')|| 50);
    localStorage.setItem('splitPos3', localStorage.getItem('splitPos3')|| 50);
  }



  language_to_id = {
    "C": 50,
    "C#": 51,
    "C++": 54,
    "Java": 62,
    "JavaScript": 63,
    "Python": 71,
  };

  id_to_language = {
    50:"C",
    51:"C#",
    54:"C++",
    62:"Java",
    63:"JavaScript",
    71:"Python",
  };



  langChange = (event) => { 
    this.setState({ language_id: this.language_to_id[event.target.value] });
    localStorage.setItem('language_id', this.language_to_id[event.target.value]);
  }; 

  codeChange = (event) => { 
    this.setState({ code: event });
    localStorage.setItem(this.props.pid, event);
  };

  inputChange = (event) => { 
    this.setState({ input: event.target.value });
  };

  expectedChange = (event) => { 
    this.setState({ expected: event.target.value });
  };

  run = async (e) => {
    this.setState({running: true});
    e.preventDefault();
    //console.log('submit', this.state.code, this.state.input, this.state.expected, this.state.language_id);
    let outputText = document.getElementById("output");
    outputText.innerHTML = "";
    outputText.innerHTML += "⚙️ Creating Submission ...\n";
    const response = await fetch(
      "https://ackeecode.com/submissions/submissions",
      {
        method: "POST",
        headers: {
          "x-rapidapi-host": "https://ackeecode.com/",
          "content-type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({
          source_code: this.state.code,
          stdin: this.state.input,
          language_id: this.state.language_id,
        }),
      }
    );
    
    outputText.innerHTML += "🎉 Submission Created ...\n";
    const jsonResponse = await response.json();

    //console.log(jsonResponse)

    let jsonGetSolution = {
      status: { description: "Queue" },
      stderr: null,
      compile_output: null,
    };
    while (
      jsonGetSolution.status.description !== "Accepted" &&
      jsonGetSolution.status.description !== "Time Limit Exceeded" &&
      jsonGetSolution.stderr == null &&
      jsonGetSolution.compile_output == null &&
      jsonGetSolution.time == null
    ) {
      outputText.innerHTML = `⚙️ Creating Submission ... \n🎉 Submission Created ...\n⏬ Checking Submission Status\nstatus : ${jsonGetSolution.status.description}`;
      if (jsonResponse.token) {
        let url = `https://ackeecode.com/submissions/submissions/${jsonResponse.token}?base64_encoded=true`;
        const getSolution = await fetch(url, {
          method: "GET",
          headers: {
            "x-rapidapi-host": "https://ackeecode.com/",
            "content-type": "application/json",
          },
        });
        jsonGetSolution = await getSolution.json();
        //console.log(jsonGetSolution);
      }
    }

    console.log(jsonGetSolution);


    if (jsonGetSolution.stderr) {
      const error = Buffer.from(jsonGetSolution.stderr, "base64").toString();
      outputText.innerHTML = "";
      outputText.innerHTML += `\n🔴 Error:\n\n${error}`;
    } 
    else if (jsonGetSolution.compile_output) {
      const compilation_error = Buffer.from(jsonGetSolution.compile_output, "base64").toString();
      outputText.innerHTML = "";
      outputText.innerHTML += `\n🔴 Compilation Error:\n\n${compilation_error}`;
    } 
    else if (jsonGetSolution.status.description == "Time Limit Exceeded") {
      outputText.innerHTML = '🔴 TIME LIMIT EXCEEDED!';
      outputText.innerHTML += `\n\nExecution Time : ${jsonGetSolution.time} Secs\nMemory used : ${jsonGetSolution.memory} bytes`;
    } 
    else if (jsonGetSolution.stdout) {
      const output = Buffer.from(jsonGetSolution.stdout, "base64").toString();
      outputText.innerHTML = "";
      if (this.state.expected) {
        if (output.trim() == this.state.expected.trim()) outputText.innerHTML += '🟢 ACCEPTED!';
        else outputText.innerHTML += '🔴 WRONG ANSWER!';
      } else {
        outputText.innerHTML += '⬤ MANUALLY CHECK YOUR ANSWER.';
      }
      outputText.innerHTML += `\n${output}\n\nExecution Time : ${jsonGetSolution.time} Secs\nMemory used : ${jsonGetSolution.memory} bytes`;
    } 
    else {
      outputText.innerHTML = '⬤ NO OUTPUT.';
      outputText.innerHTML += `\n\nExecution Time : ${jsonGetSolution.time} Secs\nMemory used : ${jsonGetSolution.memory} bytes`;
    }
    this.setState({running: false});
  };
  



  render() {
    return (
    <div className="top">
        {/* <div><h2> Online Compiler</h2></div> */}

        <SplitPane split="vertical" height="100%" resizerStyle={{ color: '#f00', opacity: 0.3, }} 
          onChange={(size) => localStorage.setItem('splitPos1', size)}
        >
            <div id="first" 
            initialSize={parseFloat(localStorage.getItem('splitPos1'), 10) + "%"}
            
            ><div id="echo_file" class="b-box_with_grand_text"><Description pid={this.props.pid}/></div></div>
            <SplitPane split="horizontal" className="third" 
              onChange={(size) => localStorage.setItem('splitPos2', size)}
            >
            <div id="second" className="resizable" initialSize={parseFloat(localStorage.getItem('splitPos2'), 10) + "%"}>
              <h3>Source Code</h3>

            <Editor id="editor" value={this.state.code} onChangeValue={this.codeChange} 
              lang={this.id_to_language[this.state.language_id]}
              onLoad={editorInstance => {
              editorInstance.container.style.resize = "both";
              // mouseup = css resize end
              document.addEventListener("mouseup", e => (
                editorInstance.resize()
              ));
            }} />
            </div>
            <SplitPane split="vertical" height="100%"
              onChange={(size) => localStorage.setItem('splitPos3', size)}
            >
            <div className="third" 
              initialSize={parseFloat(localStorage.getItem('splitPos3'), 10) + "%"}
            >
            <b>Language:  </b>
            <select id="lang" onChange={this.langChange} value={this.id_to_language[this.state.language_id]}>
                <option value="Java">Java</option>
                <option value="Python">Python</option>
                <option value="C++">C++</option>
                <option value="C">C</option>
                <option value="C#">C#</option>
                <option value="JavaScript">JavaScript</option>
                
            </select>&nbsp;&nbsp;
            <button id="run" disabled={!this.state.code || this.state.running} onClick={this.run}>▶️ Run Code</button>
            <h4>Input</h4>
            <textarea id="input" onChange={(e) => this.setState({ input: e.target.value }) } style={{width: 'calc(100% - 10px)', height: "10%", resize: "vertical"}} value={this.state.input} />
            <h4>Expected Output</h4>
            <textarea id="expected-output" onChange={this.expectedChange} style={{width: "calc(100% - 10px)", height: "10%", resize: "vertical"}} value={this.state.expected}/>

            <br /><br />
            

            
            </div><div className="third">
            <textarea readOnly id="output"></textarea>

            
            </div>
            </SplitPane>
            </SplitPane>
        </SplitPane>
    </div>
    );
  }
}

export default Problems;